<template>
  <div id="user-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-person-lines-fill"></i>
          <span style="margin-left: 5px"> Users </span>
        </b-card-title>
        <!-- add user button -->
        <b-button
          variant="outline-info"
          class="ml-auto mr-1"
          v-b-modal.modal-add-user
        >
          <feather-icon icon="PlusIcon" />
          <span>Tambah User</span>
        </b-button>
        <!-- keyword filter -->
        <b-input-group class="input-group-merge" style="width: 25vw">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Masukkan kata kunci..."
            v-model="table_data.filter"
          />
        </b-input-group>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table component -->
      <table-component
        v-else
        :data="table_data"
        :editItems="editUser"
        :deleteItems="deleteUser"
        class="mt-1"
      />
    </b-card>
    <!-- add user  modal component -->
    <add-user-component />
    <!-- edit user modal component -->
    <edit-user-component :data="user_data" />
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import addUser from "@/views/components/users/addUser.vue";
import editUser from "@/views/components/users/editUser.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBModal,
} from "bootstrap-vue";

export default {
  name: "User",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTable,
    "loader-component": loader,
    "add-user-component": addUser,
    "edit-user-component": editUser,
  },
  data() {
    return {
      is_loading: true,
      page: 1,
      items: 10,
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        filter: "",
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "nama",
            label: "nama",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "username",
            label: "username",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "email",
            label: "email",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "role",
            label: "role",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "actions",
            label: "aksi",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "5%", paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      user_data: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.getData();
    },
  },
  methods: {
    getData() {
      this.is_loading = true;
      let params = {
        page: this.page,
        limit: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "users/?" + query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          if (res.status == 200 && res.data.users.length > 0) {
            this.table_data.items = res.data.users;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
    editUser(id) {
      let filter_val = this.table_data.items.filter((el) => el._id == id);
      let user = filter_val[0];
      this.user_data = {
        nama: user.nama,
        username: user.username,
        email: user.email,
        role: user.role,
        _id: user._id,
      };
      this.$bvModal.show("modal-edit-user");
    },
    deleteUser(id, nama) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${nama}</strong> Akan dihapus dari daftar user`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api = process.env.VUE_APP_API_URL + "users/delete/" + id;
          useJwt.axiosIns
            .delete(api)
            .then(() => {
              this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `User berhasil dihapus`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `User gagal dihapus`,
                },
              });
            });
        }
      });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>